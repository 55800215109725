import { Component, OnChanges, Input, ViewEncapsulation, SimpleChanges } from '@angular/core';
import PrismicDom from 'prismic-dom';

@Component({
  selector: 'app-prismic-text',
  template: `
    <div class="prismic-text" *ngIf="htmlText" [innerHTML]="htmlText"></div>
  `,
  styles: [
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrismicTextComponent implements OnChanges {
  @Input() text: any;
  public htmlText: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.htmlText = PrismicDom.RichText.asHtml(this.text);
    } 
  }
}
