import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  showContent(): any {
    return timer(0).pipe(map(() => true), tap(console.log), share());
  }
}
