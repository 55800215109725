<div class="menu-list section-padding">

    <div class="menu-list__header decorative-header">

        <h2>{{cont?.primary.title_of_menu[0].text}}</h2>

    </div>

    <div class="menu-list__container">

        <div class="menu-list__left">

            <ng-container *ngFor="let item of cont?.items; let i = index; let even = even">

                <app-menu-item [uid]="item?.featured_cocktail.uid" *ngIf="even"></app-menu-item>

            </ng-container>

        </div>        

        <div class="menu-list__right">

            <ng-container *ngFor="let item of cont?.items; let i = index; let even = even">
                <app-menu-item [uid]="item?.featured_cocktail.uid" *ngIf="!even"></app-menu-item>

            </ng-container>

        </div>

    </div>

</div>