<div class="section-padding-no-wrapper">
  <div
    class="background-image"
    [style.background]="
      'url(' +
      content?.primary.background_image.url +
      ') no-repeat center center'
    "
  ></div>
</div>
