import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FrontPageComponent } from './pages/front-page/front-page.component';
import { DrinksComponent } from './pages/single-page/drinks/drinks.component';
import { AboutUsComponent } from './pages/single-page/about-us/about-us.component';
import { CateringComponent } from './pages/single-page/catering/catering.component';
import { ContactUsComponent } from './pages/single-page/contact-us/contact-us.component';
import { NotFoundComponent } from './pages/single-page/not-found/not-found.component';
import { MosiacComponent } from './pages/single-page/mosiac/mosiac.component';
import { CustomPageComponent } from './pages/custom-page/custom-page.component';

const routes: Routes = [
  { path: '', component: FrontPageComponent, data: { animation: 'isLeft' } },
  {
    path: 'drinks',
    component: DrinksComponent,
    data: { animation: 'isRight' },
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { animation: 'isRight' },
  },
  {
    path: 'catering',
    component: CateringComponent,
    data: { animation: 'isRight' },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: { animation: 'isLeft' },
  },
  {
    path: 'gallery',
    component: MosiacComponent,
    data: { animation: 'isLeft' },
  },

  {
    path: 'not-found',
    component: NotFoundComponent,
    data: { animation: 'isLeft' },
  },
  {
    path: '**',
    component: CustomPageComponent,
    data: { animation: 'isLeft' },
  },
  // { path: '**', component: NotFoundComponent, data: { animation: 'page' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
