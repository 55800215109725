import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subpage-hero-banner',
  templateUrl: './subpage-hero-banner.component.html',
  styleUrls: ['./subpage-hero-banner.component.less'],
})
export class SubpageHeroBannerComponent {
  @Input() content;
  @Input() loaded;
}
