<a (mouseenter)="flipCard(true)" (mouseleave)="flipCard(false)">
  <div class="single-product-card" [ngClass]="{ flipped: cardFlipped }">
    <div
      class="single-product-card__front"
      [style.background]="
        'url(' + content?.image_of_cocktail.url + ') no-repeat center center'
      "
    >
      <h4>{{ content?.name_of_cocktail[0].text }}</h4>

      <div class="overlay"></div>
    </div>

    <div class="single-product-card__back">
      <div class="header">
        <h4>{{ content?.name_of_cocktail[0].text }}</h4>

        <span class="price">
          {{ content?.price }}
        </span>
      </div>

      <div class="content">
        <ul>
          <li *ngFor="let item of content?.cocktail_ingredients_list">
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</a>
