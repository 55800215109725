import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.less'],
})
export class MobileNavigationComponent {
  @Input() content;
  @Input() showMenu: boolean;
  @Output() closeNav = new EventEmitter<any>();

  closeNavigation() {
    this.showMenu = false;
    setTimeout(() => {
      this.closeNav.emit('close');
    }, 300);
  }
}
