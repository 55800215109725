import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '../../../services/prismic/prismic.service';
import { GeneralService } from '../../../services/general/general.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../../models/prismic-base.interface';

@Component({
  selector: 'app-single-product-card',
  templateUrl: './single-product-card.component.html',
  styleUrls: ['./single-product-card.component.less'],
})
export class SingleProductCardComponent implements OnInit {
  @Input() uid;
  @Input() featured?;
  @ViewChild('single-product-card') cardEl: ElementRef;
  public content: any;
  public loaded: Observable<boolean>;
  cardFlipped = false;

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  getPrismicContent() {
    this.prismic
      .getDrinkByUid(this.uid)
      .then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

  flipCard(forceFlip?: boolean): void {
    if (forceFlip) {
      this.cardFlipped = forceFlip;
    } else {
      !this.cardFlipped
        ? (this.cardFlipped = true)
        : (this.cardFlipped = false);
    }
  }
}
