<div class="wrapper">
  <ngx-masonry [ordered]="true" [options]="masonryOptions">
    <div
      ngxMasonryItem
      class="masonry-item"
      *ngFor="let image of content.gallery; let i = index"
    >
      <div class="container">
        <img [src]="image.gallery_picture.url" alt="" width="100%" />
      </div>
    </div>
  </ngx-masonry>
</div>
