import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '../../../services/prismic/prismic.service';
import { GeneralService } from '../../../services/general/general.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../../models/prismic-base.interface';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.less']
})
export class MenuItemComponent implements OnInit {
  @Input() uid;
  @Input() featured?;
  public content: any;
  public loaded: Observable<boolean>;

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) { }

  private getPrismicContent(): void {
    if (this.featured) {
      this.prismic.getDrinkByUid(this.uid).then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      }).then(()=> {
        this.loaded = this.general.showContent();
      })
    } else {
      this.prismic.getDrinkByUid(this.uid).then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      }).then(()=> {
        this.loaded = this.general.showContent();
      })
    }
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe(ref => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

}
