<div class="header" [ngClass]="{ fadein: (loaded | async) }">
  <div class="header__left">
    <div class="logo" routerLink="/">
      <img
        class="logo__img"
        src="assets/jungle-logo.png"
        alt="Jungle Bar Logo"
      />
    </div>
    <nav>
      <ul>
        <ng-container *ngFor="let nav of content?.navigation">
          <ng-container
            *ngIf="
              nav.navigation_item.uid === 'front-page';
              else regularNavigation
            "
          >
            <li
              routerLink=""
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a>Home</a>
            </li>
          </ng-container>
          <a
            *ngIf="nav.external_link.link_type === 'Web'"
            [href]="nav.external_link.url"
            target="_blank"
            >{{ nav.title }}</a
          >
          <ng-template #regularNavigation>
            <ng-container *ngIf="nav.external_link.link_type !== 'Web'">
              <li
                [routerLink]="nav.navigation_item.uid"
                routerLinkActive="active"
              >
                <a>{{ nav.title }}</a>
              </li>
            </ng-container>
          </ng-template>
        </ng-container>
      </ul>
    </nav>
  </div>

  <div class="navigation">
    <div class="social-media">
      <div class="instagram" *ngIf="content?.instagram">
        <a [href]="content?.instagram_link.url" target="_blank">
          <img src="../../../assets/icons/instagram.png" alt="Instagram" />
        </a>
      </div>
      <div class="facebook" *ngIf="content?.facebook">
        <a [href]="content?.facebook_link.url" target="new">
          <img src="../../../assets/icons/facebook.png" alt="Facebook Icon" />
        </a>
      </div>
    </div>
    <div class="burger" (click)="toggleNavigation()"></div>
  </div>
</div>

<app-mobile-navigation
  [content]="content?.navigation"
  [showMenu]="showMobileNavigation"
  (closeNav)="toggleNavigation($event)"
></app-mobile-navigation>
