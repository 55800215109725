<ng-container *ngIf="content">
  <app-hero-banner [content]="content" [loaded]="loaded"></app-hero-banner>
  <ng-container *ngFor="let slice of content?.body">
    <app-img-text
      *ngIf="slice.slice_type === 'image___text'"
      [content]="slice"
      [loaded]="loaded"
    ></app-img-text>
    <section>
      <app-background-img
        *ngIf="slice.slice_type === 'background_image'"
        [content]="slice"
        [loaded]="loaded"
      ></app-background-img>
    </section>
    <app-featured-menu
      *ngIf="slice.slice_type === 'featured_cocktails'"
      [content]="slice"
      [loaded]="loaded"
    ></app-featured-menu>
  </ng-container>
  <app-instagram-feed></app-instagram-feed>
</ng-container>
