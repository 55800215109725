<ng-container *ngFor="let slice of content?.body">
  <app-subpage-hero-banner
    [content]="slice"
    [loaded]="loaded"
    *ngIf="slice.slice_type === 'subpage_hero_banner'"
  ></app-subpage-hero-banner>
  <app-img-product-grid
    [content]="slice"
    *ngIf="slice.slice_type === 'cocktails_grid'"
  ></app-img-product-grid>
  <app-menu-list
    [content]="slice"
    *ngIf="slice.slice_type === 'drinks_menu'"
  ></app-menu-list>
  <app-menu-image
    [content]="slice"
    *ngIf="slice.slice_type === 'menu'"
  ></app-menu-image>
  <app-img-employee-grid
    [content]="slice"
    *ngIf="slice.slice_type === 'bartenders_grid'"
  ></app-img-employee-grid>
  <app-img-text
    [content]="slice"
    *ngIf="slice.slice_type === 'image___text'"
  ></app-img-text>
  <app-mosiac-grid
    [content]="slice"
    *ngIf="slice.slice_type === 'mosiac'"
  ></app-mosiac-grid>
</ng-container>
