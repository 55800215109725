import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '../../../services/prismic/prismic.service';
import { GeneralService } from '../../../services/general/general.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../../models/prismic-base.interface';

@Component({
  selector: 'app-catering',
  templateUrl: '../single-page.component.html',
  styleUrls: ['./catering.component.less']
})
export class CateringComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) { }

  private getPrismicContent(): void {
    this.prismic.getDocumentByType('veisluthjonusta').then((res: PrismicBaseInterface) => {
      this.content = res.results[0].data;
    }).then(()=> {
      this.loaded = this.general.showContent();
    })
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe(ref => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

}
