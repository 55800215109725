<div class="contact-form section-padding-lg">
  <div class="contact-form__header">
    <h2>Contact Us</h2>
  </div>

  <form class="form" (ngSubmit)="checkErrors(contact)" #contact="ngForm">
    <div class="form-container">
      <div class="form__left">
        <div class="form-group">
          <label for="name">
            Your name
            <ng-container *ngIf="name.invalid && (name.touched || name.dirty)">
              <div class="error">Name is required</div>
            </ng-container>
          </label>
          <input
            type="text"
            placeholder="Your name..."
            name="name"
            id="name"
            [(ngModel)]="contactForm.name"
            #name="ngModel"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">
            Your email
            <ng-container
              *ngIf="email.invalid && (email.touched || email.dirty)"
            >
              <div class="error">Email is required</div>
            </ng-container>

            <ng-container
              *ngIf="
                email.invalid && netfangBlur && (email.touched || email.dirty)
              "
            >
              <div class="error">Email has to be valid</div>
            </ng-container>
          </label>
          <input
            type="email"
            placeholder="Your email..."
            name="email"
            id="email"
            [(ngModel)]="contactForm.email"
            #email="ngModel"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            (blur)="netfangBlur = true"
          />
        </div>
        <div class="form-group">
          <label for="phone">Your phone (optional)</label>
          <input
            type="text"
            name="phone"
            placeholder="Símarnr þitt..."
            id="phone"
            [(ngModel)]="contactForm.phone"
            #phone="ngModel"
          />
        </div>
      </div>

      <div class="form__right">
        <div class="form-group">
          <label for="message">
            Your message
            <ng-container
              *ngIf="!message.valid && (message.touched || message.dirty)"
            >
              <div class="error">Message is required</div>
            </ng-container>
          </label>
          <textarea
            cols="30"
            rows="12"
            placeholder="Skilaboðinn þín..."
            name="message"
            id="message"
            [(ngModel)]="contactForm.message"
            #message="ngModel"
            required
          ></textarea>
        </div>
      </div>
    </div>

    <button type="submit">Send Message</button>

    <ng-container *ngIf="success">
      <div class="success">Your inquery has been sent!</div>
    </ng-container>
    <ng-container *ngIf="error">
      <div class="error">
        There was an error with your inquery, please try again later!
      </div>
    </ng-container>
  </form>
</div>
