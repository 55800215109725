import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-img-product-grid',
  templateUrl: './img-product-grid.component.html',
  styleUrls: ['./img-product-grid.component.less'],
})
export class ImgProductGridComponent {
  @Input() content;
}
