<div class="img-text section-padding">
    <div class="img-text__media half-width">
        <img [src]="content?.primary.image.url" [alt]="content?.primary.image.alt">
    </div>

    <div class="img-text__content half-width">
        <h2>{{content?.primary.title[0].text}}</h2>
        <app-prismic-text [text]="content?.primary.content"></app-prismic-text>
        <a class="btn-anchor" [href]="content?.primary.button_link.uid" *ngIf="content?.primary.button_link.uid">
            <button class="btn-outline">{{content.primary.button_content}}</button>
        </a>
    </div>
</div>
