<div class="menu-item">
    <div class="menu-item__header">
        <h3>{{content?.name_of_cocktail[0].text}}</h3>
        <span class="price">
            {{content?.price}}
        </span>
    </div>

    <div class="menu-item__content">
        <app-prismic-text [text]="content?.cocktial_ingredients"></app-prismic-text>
    </div>
</div>
