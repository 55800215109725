<div
  class="mobile-navigation"
  [ngClass]="[showMenu ? 'animateIn' : 'animateOut']"
>
  <div class="mobile-navigation__content">
    <div class="mobile-navigation__header">
      <div class="logo" routerLink="/">
        <img
          class="logo__img"
          src="assets/jungle-logo.png"
          alt="Jungle Bar Logo"
        />
      </div>
      <a class="exit" (click)="closeNavigation()">
        <img src="../../../../assets/icons/x.svg" alt="Exit" />
      </a>
    </div>
    <nav>
      <ul>
        <ng-container *ngFor="let nav of content">
          <ng-container
            *ngIf="
              nav.navigation_item.uid === 'front-page';
              else regularNavigation
            "
          >
            <li
              (click)="closeNavigation()"
              routerLink=""
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a>Home</a>
            </li>
          </ng-container>
          <li *ngIf="nav.external_link.link_type === 'Web'">
            <a [href]="nav.external_link.url" target="_blank">{{
              nav.title
            }}</a>
          </li>

          <ng-template #regularNavigation>
            <ng-container *ngIf="nav.external_link.link_type !== 'Web'">
              <li
                (click)="closeNavigation()"
                [routerLink]="nav.navigation_item.uid"
                routerLinkActive="active"
              >
                <a>{{ nav.title }}</a>
              </li>
            </ng-container>
          </ng-template>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>
