import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// Services
import { PrismicService } from '../../services/prismic/prismic.service';
import { GeneralService } from '../../services/general/general.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../models/prismic-base.interface';

@Component({
  selector: 'app-custom-page',
  templateUrl: '../single-page/single-page.component.html',
  styles: [],
})
export class CustomPageComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;

  constructor(
    private router: Router,
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  private getPrismicContent(): void {
    let url = this.router.url.split('/').pop();
    this.prismic
      .getCustomPageByUid(url)
      .then((res: PrismicBaseInterface) => {
        console.log(res);
        if (res.total_results_size === 0) {
          this.router.navigate(['/not-found']);
        } else {
          this.content = res.results[0].data;
        }
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }
}
