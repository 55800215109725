<footer class="footer section-padding">

    <ng-container *ngFor="let section of content?.body">

        <div class="footer__navigation" *ngIf="section.slice_type === 'footer_navigation'">
            <h5>Valmynd</h5>
            <ul>
                <ng-container *ngFor="let nav of section?.items">

                    <ng-container *ngIf="nav.navigation_item.uid === 'front-page'; else regularNavigation">
                        <li routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a>Home</a>
                        </li>
                    </ng-container>
                    
                    <ng-template #regularNavigation>
                        <li [routerLink]="nav.navigation_item.uid" routerLinkActive="active">
                            <a>{{nav.navigation_title}}</a>
                        </li>
                    </ng-template>

                </ng-container>
            </ul>
        </div>
        
        <div class="footer__address"  *ngIf="section.slice_type === 'footer_text'"> 
            <h5>{{section.primary.title_of_column[0].text}}</h5>
            <div class="container">
                <app-prismic-text [text]="section.primary.contents_of_column"></app-prismic-text>
            </div>
        </div>
    </ng-container>
</footer>
<div class="footer-maps section-padding">
    <div class="container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1739.7653152265991!2d-21.94167984791255!3d64.14779378351527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d60bc943f35efb%3A0x6d243885f6dff020!2sJungle%20Cocktail%20Bar!5e0!3m2!1sen!2sis!4v1612374839130!5m2!1sen!2sis" width="100%" height="260" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
</div>
