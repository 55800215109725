import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { PrismicService } from '../../services/prismic/prismic.service';
import { GeneralService } from '../../services/general/general.service';
// Utils
import { Observable } from 'rxjs';
// Types
import { PrismicBaseInterface } from '../../models/prismic-base.interface';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styles: [],
})
export class SinglePageComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;

  constructor(
    private router: Router,
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  private getPrismicContent(): void {
    const href = this.router.url.split('/').pop();
    this.prismic
      .getDocumentByType(href)
      .then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }
}
