<div class="main-background">
  <app-header></app-header>

  <div class="height-wrapper" style="min-height: 100vh">
    <div>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
</div>
