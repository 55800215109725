import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '../../services/prismic/prismic.service';
import { GeneralService } from '../../services/general/general.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../models/prismic-base.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;
  public showMobileNavigation: boolean = false;

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  private getPrismicContent(): void {
    this.prismic
      .getDocumentByType('header')
      .then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

  public toggleNavigation(forceclose?): void {
    if (forceclose) {
      this.showMobileNavigation = false;
    } else if (this.showMobileNavigation === false) {
      this.showMobileNavigation = true;
    } else {
      this.showMobileNavigation = false;
    }
  }
}
