import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

import { PrismicMasterRef } from '../../models/masterref.interface';

@Injectable({
  providedIn: 'root',
})
export class PrismicService {
  private env = environment;
  private documentUrl = '/documents/search?ref=';
  private url = this.env.prismicUrl + this.documentUrl;

  masterRef = new BehaviorSubject('');

  constructor(private http: HttpClient) {}

  getMasterRef() {
    return this.http.get<PrismicMasterRef>(this.env.prismicUrl);
  }

  setMasterRef() {
    if (!this.masterRef.getValue()) {
      this.getMasterRef().subscribe((ref) => {
        this.masterRef.next(ref.refs[0].ref);
      });
    }
  }

  getDocumentByType(type) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.url +
        this.masterRef.getValue() +
        `&q=[[at(document.type, "${type}")]]`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then((res) => {
          resolve(res);
        });
    });
    return promise;
  }

  getDrinkByUid(UID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.url +
        this.masterRef.getValue() +
        `&q=[[at(my.cocktail.uid, "${UID}")]]`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then((res) => {
          resolve(res);
        });
    });
    return promise;
  }

  getBartenderByUid(UID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.url +
        this.masterRef.getValue() +
        `&q=[[at(document.id, "${UID}")]]`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then((res) => {
          resolve(res);
        });
    });
    return promise;
  }

  getCustomPageByUid(UID) {
    const promise = new Promise((resolve, reject) => {
      const apiUrl =
        this.url +
        this.masterRef.getValue() +
        `&q=[[at(my.custom_page.uid, "${UID}")]]`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then((res) => {
          resolve(res);
        });
    });
    return promise;
  }
}
