import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-background-img',
  templateUrl: './background-img.component.html',
  styleUrls: ['./background-img.component.less']
})
export class BackgroundImgComponent {
  @Input() content;
  @Input() loaded;
}
