<div class="employee-card">
  <div class="employee-card__media">
    <img
      class="desktop"
      [src]="content?.image_of_bartender.url"
      [alt]="content?.image_of_bartender.alt"
    />
  </div>

  <div class="employee-card__content">
    <h3>{{ content?.name_of_bartender[0].text }}</h3>
    <p>
      {{ content?.description_of_barteder[0].text }}
    </p>
  </div>
</div>
