import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-featured-menu',
  templateUrl: './featured-menu.component.html',
  styleUrls: ['./featured-menu.component.less']
})
export class FeaturedMenuComponent {
  @Input() content;
  @Input() loaded;
}
