import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-image',
  templateUrl: './menu-image.component.html',
  styleUrls: ['./menu-image.component.less'],
})
export class MenuImageComponent {
  @Input() content;
}
