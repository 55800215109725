<div class="img-employee-grid section-padding">
    <div class="img-employee-grid__header decorative-header">
        <h2>{{content?.primary.section_title[0].text}}</h2>
    </div>

    <div class="img-employee-grid__content three-grid">
        <ng-container *ngFor="let employee of content?.items">
            <app-employee-card [uid]="employee?.bartender.id"></app-employee-card>
        </ng-container>
    </div>
</div>
