import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 
// Models
import { ContactForm } from '../../../models/contact-form.class';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent {
  private env = environment;
  private flowUrl = this.env.flowUrl;
  public netfangBlur = false;
  public success = false;
  public error = false;
  public contactForm = new ContactForm('', '', '', '');

  constructor(private http: HttpClient) { }

  sendEmail() {
    const query = {
      properties: this.contactForm
    };
    this.http.post(this.flowUrl, this.contactForm).subscribe((data: any) => {
      this.success = true;
    }, err => {
      this.error = true;
    });
  }

  checkErrors(args) {
    args.form.updateValueAndValidity();

    if (args.form.invalid) {
      Object.keys(args.form.controls).forEach(key => {
        args.form.get(key).markAsDirty();
      });
      return;

    } else {
      this.sendEmail();
    }
  }
}
