import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.less']
})
export class HeroBannerComponent {
  @Input() content;
  @Input() loaded;
}
