<div
  class="hero-banner fadeinQuick"
  [style.background]="
    'url(' + content?.hero_banner_background.url + ') no-repeat center center'
  "
  [ngClass]="{ fadein: (loaded | async) }"
>
  <div class="hero-banner__content">
    <ng-container *ngIf="content?.hero_banner_title">
      <h1 class="fadeinTransition">{{ content?.hero_banner_title[0].text }}</h1>
    </ng-container>

    <a
      class="btn-anchor fadeinTransition"
      [routerLink]="content?.hero_banner_button[0].button_link.uid"
    >
      <button class="btn-outline">
        {{ content?.hero_banner_button[0].button_content }}
      </button>
    </a>
  </div>
  <div class="hero-banner__overlay"></div>
</div>
