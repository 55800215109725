import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { InstagramFeedComponent } from './components/instagram-feed/instagram-feed.component';
import { BackgroundImgComponent } from './components/background-img/background-img.component';
import { ImgTextComponent } from './components/img-text/img-text.component';
import { FeaturedMenuComponent } from './components/featured-menu/featured-menu.component';
import { SubpageHeroBannerComponent } from './components/subpage-hero-banner/subpage-hero-banner.component';
import { ImgProductGridComponent } from './components/img-product-grid/img-product-grid.component';
import { SingleProductCardComponent } from './components/img-product-grid/single-product-card/single-product-card.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { ImgEmployeeGridComponent } from './components/img-employee-grid/img-employee-grid.component';
import { EmployeeCardComponent } from './components/img-employee-grid/employee-card/employee-card.component';
import { FrontPageComponent } from './pages/front-page/front-page.component';
import { MobileNavigationComponent } from './components/header/mobile-navigation/mobile-navigation.component';
import { MenuItemComponent } from './components/menu-list/menu-item/menu-item.component';
import { DrinksComponent } from './pages/single-page/drinks/drinks.component';
import { AboutUsComponent } from './pages/single-page/about-us/about-us.component';
import { CateringComponent } from './pages/single-page/catering/catering.component';
import { ContactUsComponent } from './pages/single-page/contact-us/contact-us.component';
import { NotFoundComponent } from './pages/single-page/not-found/not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PrismicTextComponent } from './components/prismic-text/prismic-text.component';
import { MenuImageComponent } from './components/menu-image/menu-image.component';
import { MosiacComponent } from './pages/single-page/mosiac/mosiac.component';
import { SinglePageComponent } from './pages/single-page/single-page.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { CustomPageComponent } from './pages/custom-page/custom-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeroBannerComponent,
    InstagramFeedComponent,
    BackgroundImgComponent,
    ImgTextComponent,
    FeaturedMenuComponent,
    SubpageHeroBannerComponent,
    ImgProductGridComponent,
    SingleProductCardComponent,
    MenuListComponent,
    ImgEmployeeGridComponent,
    EmployeeCardComponent,
    FrontPageComponent,
    MobileNavigationComponent,
    MenuItemComponent,
    DrinksComponent,
    AboutUsComponent,
    CateringComponent,
    ContactUsComponent,
    NotFoundComponent,
    PrismicTextComponent,
    MenuImageComponent,
    MosiacComponent,
    SinglePageComponent,
    CustomPageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgxMasonryModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
