<div
  class="subpage-hero-banner fadeinQuick"
  [style.background]="
    'url(' + content?.primary.banner_image.url + ') no-repeat center center'
  "
  [ngClass]="{ fadein: (loaded | async) }"
>
  <div class="subpage-hero-banner__content">
    <h1 class="fadeinTransition">
      {{ content?.primary.banner_title[0].text }}
    </h1>
  </div>
  <div class="subpage-hero-banner__overlay"></div>
</div>
