<div class="featured-menu section-padding">
    <div class="featured-menu__header">
        <h2>{{content?.primary.section_title[0].text}}</h2>
        <p *ngFor="let text of content?.primary.section_excerpt">
            {{text.text}}
        </p>
        <app-prismic-text [text]="content?.primary.section_excerpt"></app-prismic-text>
    </div>

    <div class="content">
        <div class="content__menu half-width">
            <ng-container *ngFor="let item of content?.items">
                <app-menu-item [uid]="item?.featured_cocktail.uid" [featured]="'true'"></app-menu-item>
            </ng-container>
    
            <a [routerLink]="content?.primary.menu_link.uid" class="btn-anchor" *ngIf="content?.primary.menu_link">
                <button class="btn-outline">{{content?.primary.button_contents}}</button>
            </a>
        </div>

        <div class="content__media half-width">
            <img [src]="content?.primary.section_image.url" [alt]="content?.primary.section_image.alt">
        </div>
    </div>
</div>
