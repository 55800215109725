import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-img-text',
  templateUrl: './img-text.component.html',
  styleUrls: ['./img-text.component.less']
})
export class ImgTextComponent {
  @Input() content;
  @Input() loaded;
}
