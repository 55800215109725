import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '../../services/prismic/prismic.service';
// Models & Interfaces
import { PrismicBaseInterface } from '../../models/prismic-base.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;
  public showMobileNavigation: boolean = false;

  constructor(private prismic: PrismicService) {}

  private getPrismicFooter(): void {
    this.prismic
      .getDocumentByType('footer')
      .then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicFooter();
      }
    });
  }
}
