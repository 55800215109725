import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { PrismicService } from '../../../services/prismic/prismic.service';
import { GeneralService } from '../../../services/general/general.service';
// Utils
import { Observable } from 'rxjs';
import { NgxMasonryOptions } from 'ngx-masonry';
// Types
import { PrismicBaseInterface } from '../../../models/prismic-base.interface';

@Component({
  selector: 'app-mosiac',
  templateUrl: './mosiac.component.html',
  styleUrls: ['./mosiac.component.less'],
})
export class MosiacComponent implements OnInit {
  public content: any;
  public loaded: Observable<boolean>;

  public masonryOptions: NgxMasonryOptions = {
    // gutter: 50,
    originTop: true,
    resize: true,
  };

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  private getPrismicContent(): void {
    this.prismic
      .getDocumentByType('mosiac')
      .then((res: PrismicBaseInterface) => {
        this.content = res.results[0].data;
        console.log(this.content);
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  ngOnInit(): void {
    this.prismic.masterRef.subscribe((ref) => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }
}
